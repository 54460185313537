$(function () {
    $('.slider').slick({
        autoplay: true,
        draggable: true,
        fade: true,
        speed: 1000,
        arrows: true,
        dots: true,
        prevArrow: '<a href="javascript:;" class="prev"><i class="fa fa-caret-left" aria-hidden="true"></i></a>',
        nextArrow: '<a href="javascript:;" class="next"><i class="fa fa-caret-right" aria-hidden="true"></i></a>'
    });
    $('.machine-detail-slider').slick({
        autoplay: true,
        draggable: true,
        fade: true,
        speed: 1000,
        arrows: false,
        dots: true,
        prevArrow: '<a href="javascript:;" class="prev"><i class="fa fa-caret-left" aria-hidden="true"></i></a>',
        nextArrow: '<a href="javascript:;" class="next"><i class="fa fa-caret-right" aria-hidden="true"></i></a>'
    });
});


$('section.corporate-movie i.play').click(function () {
    $(this).parents('.preview').hide();
    $('#video-embed').removeClass('hidden');
    $("#video-embed iframe").attr('src', $("#video-embed iframe").attr('src') + '?autoplay=1');
});

$('.menu-opener').click(function () {
    $('body').addClass('menu-opened');
});
$('.closer').click(function () {
    $('body').removeClass('menu-opened');
});