$('.main-slider').slick({
    dots: true,
    infinite: true,
    fade: false,
    slide: 'div',
    slidesToShow: 1,
    autoplay: false,
    nextArrow: '<a href="javascript:;" class="next"><i class="fa fa-angle-down" aria-hidden="true"></i></a>',
    prevArrow: '<a href="javascript:;" class="prev"><i class="fa fa-angle-up" aria-hidden="true"></i></a>',
    customPaging: function (slider, i) {
        return '<button class="tab">' + $('.slider-nav li:nth-child(' + (i + 1) + ')').html() + '</button>';
    }
});

$('.project-slider').slick({
    dots: false,
    infinite: false,
    fade: false,
    slide: 'div',
    slidesToShow: 4,
    autoplay: false,
    nextArrow: '<a href="javascript:;" class="next"><i class="fa fa-angle-right" aria-hidden="true"></i></a>',
    prevArrow: '<a href="javascript:;" class="prev"><i class="fa fa-angle-left" aria-hidden="true"></i></a>'
});